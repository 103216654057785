// Imports
import * as contentful from 'contentful'

// Define plugin
export default defineNuxtPlugin((nuxtApp) => {
  // Use runtime config
  const config = useRuntimeConfig().public

  // Options
  const options = {
    accessToken: config.contentful.access_token,
    host: config.contentful.host,
    space: config.contentful.space_id
  }

  // Client
  const client = contentful.createClient(options)

  // Return client
  return {
    provide: {
      contentful: client,
    },
  }

})