import MobileDetect from "mobile-detect"

export default defineNuxtRouteMiddleware((to, from) => {
  // Skip middleware on server
  if (import.meta.server) return

  // State
  const isMobile = useState<string | null>('isMobile', () => null)

  // Initialize mobile detection
  let md = new MobileDetect(window.navigator.userAgent)

  // Detect mobile
  isMobile.value = md.mobile()

})