import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import mobile_45global from "/opt/build/repo/middleware/mobile.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  mobile_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  generated: () => import("/opt/build/repo/middleware/generated.ts")
}