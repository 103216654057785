import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as outron0g6wz7SYLMeta } from "/opt/build/repo/pages/outro.vue?macro=true";
import { default as submitejrGvDjRPcMeta } from "/opt/build/repo/pages/submit.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "outro",
    path: "/outro",
    component: () => import("/opt/build/repo/pages/outro.vue").then(m => m.default || m)
  },
  {
    name: "submit",
    path: "/submit",
    component: () => import("/opt/build/repo/pages/submit.vue").then(m => m.default || m)
  }
]