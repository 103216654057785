import mitt from 'mitt'

export default defineNuxtPlugin((nuxtApp) => {
  // Emitter
  const emitter = mitt()

  // Return bus
  return {
    provide: {
      bus: {
        $on: emitter.on,
        $off: emitter.off,
        $emit: emitter.emit
      }
    }
  }
})